.btn {
    box-shadow: $btn-box-shadow !important;
    font-size: 0.95rem;
    --bs-btn-border-radius: 0.15rem;
}

.btn:hover {
    box-shadow: $btn-focus-box-shadow !important;
}

.btn:not(.btn-group > .btn) {
    margin: 0.25rem 0.125rem;
}

.btn-primary {
    --bs-btn-color: var(--bs-white) !important;
    --bs-btn-bg: var($blue) !important;
    --bs-btn-border-color: var($blue) !important;
    --bs-btn-hover-color: var(--bs-white) !important;
    --bs-btn-hover-bg: #{shade-color($blue, 10%)} !important;
    --bs-btn-hover-border-color: #{shade-color($blue, 10%)} !important;
    --bs-btn-focus-shadow-rgb: $btn-box-shadow !important;
    --bs-btn-active-color: var(--bs-btn-hover-color) !important;
    --bs-btn-active-bg: #{shade-color($blue, 20%)} !important;
    --bs-btn-active-border-color: #{shade-color($blue, 20%)} !important;
    --bs-btn-disabled-color: var(--bs-white) !important;
}

.btn-info {
    --bs-btn-color: var(--bs-white) !important;
}

button:focus {
    outline: none !important;
    box-shadow: none !important;
}

*[class*='btn-outline-'] {
    --bs-btn-border-color: transparent;
}

.btn:disabled,
.btn.disabled {
    border-color: transparent !important;
}
