markdown {
    blockquote {
        border-left: 3px solid $gray-300;
        padding-left: 12px;
        color: $gray-600;
    }

    img {
        max-width: fit-content;
        max-width: -webkit-fill-available;
    }
}
