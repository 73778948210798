.modal-content {
    border: none;
    border-radius: 0.125rem;
    box-shadow:
        0 5px 11px 0 $standard-shadow-color,
        0 4px 15px 0 $standard-shadow-color;

    .modal-header {
        background-color: $primary;
        border-radius: 0.125rem;
        height: 50px;
        box-shadow:
            0 2px 5px 0 $standard-shadow-color,
            0 2px 10px 0 $standard-shadow-color;
        justify-content: space-between;

        .modal-title {
            font-family: $standard-fonts;
            font-weight: 300;
            font-size: 18px;
            color: $white;
            line-height: 0.8em;
        }

        .close {
            background: transparent !important;
            outline: none !important;
            border: none !important;
            font-weight: 500;
            line-height: 0.4em;
            color: $white;
            text-shadow: none;
            font-size: 1.5rem;
        }

        .close:hover {
            color: lighten($black, 80);
        }
    }

    .modal-body {
        padding: 10px;
        font-family: $standard-fonts;
        font-size: 15px;
        font-weight: 300;
    }

    .modal-footer {
        padding: 7px;
        border-top: solid 1px $standard-border-color;
    }
}
