/* You can add global styles to this file, and also import other style files */

// Import variables, icons, ...
@import 'variables.scss';
@import './general-icons/general-icons.scss';

// Import npm package styles (e.g. bootstrap, fortawesome)
@import 'node_modules/bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'node_modules/ngx-toastr/toastr.css';
@import 'node_modules/ng-wizard3/themes/ng_wizard.min.css';
@import '~tippy.js/dist/tippy.css';
@import '~shepherd.js/dist/css/shepherd.css';
@import '~prismjs/themes/prism-okaidia.css';
@import 'node_modules/flag-icons/css/flag-icons.min.css';

// Import third-party styles
@import './third-party/shepherd/shepherd.scss';
@import './third-party/ng-select/ng-select.material.theme.scss';
@import './third-party/ngb-nav/ngb-nav.scss';
@import './third-party/modal-dialogs/modal-dialogs.scss';
@import './third-party/standard-accordion/standard-accordion.scss';
@import './third-party/monaco/monaco.scss';
@import './third-party/ngx-color-picker/overrides.scss';
@import './third-party/ngx-datatable/ngx-datatable-material.scss';
@import './third-party/ngx-markdown/ngx-markdown.scss';
@import './third-party/ngb-date-picker/ngb-date-picker.scss';

// Import styles
@import './buttons/buttons.scss';
@import './standard-container/standard-container.scss';
@import './overviews/overviews.scss';
@import './global-styles/global-styles.scss';
