@import '/src/stylesheets/variables.scss';

.default-icon-color {
    color: $default-icon-color;
}

.light-icon-color {
    color: $light-icon-color;
}

.standard-icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.add-icon::before {
    @extend .standard-icon;
    // plus icon
    content: '\f067';
}

.edit-icon::before {
    @extend .standard-icon;
    // pencil alt icon
    content: '\f303';
}

.copy-icon::before {
    @extend .standard-icon;
    // copy icon
    content: '\f0c5';
}

.delete-icon::before {
    @extend .standard-icon;
    // trash alt icon
    content: '\f2ed';
}

.save-icon::before {
    @extend .standard-icon;
    // save icon
    content: '\f0c7';
}

.cancel-icon::before {
    @extend .standard-icon;
    // times circle icon
    content: '\f057';
    font-weight: 400;
}

.fullscreen-icon::before {
    @extend .standard-icon;
    // pencil alt icon
    content: '\f065';
}

.compress-icon::before {
    @extend .standard-icon;
    // pencil alt icon
    content: '\f066';
}
