@import '/src/stylesheets/variables.scss';

.accordion {
    .accordion-item {
        .accordion-header {
            .accordion-button {
                background-color: $standard-components-background-color !important;
                border-bottom: solid 1px $standard-border-color !important;
                box-shadow: none;
                padding: 0.525rem 0.938rem;
                font-size: 1.125rem;
                font-weight: 300;
                color: $gray-900;

                &:hover {
                    color: lighten($gray-900, 35);
                }
            }
        }
    }

    .accordion-body {
        padding-left: 0.313rem;
        padding-right: 0.313rem;
    }
}
