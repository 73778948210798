.color-picker .hue {
    background-image: url('/assets/images/ngx-color-picker/color-palette.png') !important;
}

.color-picker .value {
    background-image: url('/assets/images/ngx-color-picker/gray-palette.png') !important;
}

.color-picker .alpha {
    background-image: url('/assets/images/ngx-color-picker/alpha-palette.png') !important;
}

.color-picker .type-policy {
    background-image: url('/assets/images/ngx-color-picker/type.png') !important;
}

.color-picker .selected-color-background {
    background-image: url('/assets/images/ngx-color-picker/background.png') !important;
}

.color-picker .saturation-lightness {
    background-image: url('/assets/images/ngx-color-picker/saturation.png') !important;
}
