@import '/src/stylesheets/variables.scss';

$large-grid-breakpoint: map-get($grid-breakpoints, lg);

.standard-overviews {
    height: 100%;
    background-color: $white;

    .cards-container {
        height: calc(100% - 55px);
    }

    .cards-container::-webkit-scrollbar {
        width: 7px;
    }

    .cards-container::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 5px var(--sidebar_bg_color);
    }

    .cards-container::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 5px var(--sidebar_bg_color);
    }

    .card-columns {
        display: grid;
        grid-gap: 1.25rem;
        grid-auto-flow: row;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: max-content;

        @media (max-width: $large-grid-breakpoint) {
            grid-template-columns: repeat(1, auto);
        }
    }

    .overview-card {
        @extend .card;
    }

    .overview-card-header {
        @extend .card-header;
        height: 49px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis !important;
        line-height: 1.9em;
    }

    .overview-card-body {
        @extend .card-body;
    }

    .overview-link {
        color: inherit;
        text-decoration: none;
    }

    .overview-link:hover {
        text-decoration: none;
    }

    .overview-pagination {
        line-height: 1.4em;
        background-color: $white;
        border: solid 1px $standard-border-color;
        box-shadow: 5px 5px 12px $standard-shadow-color;
        border-radius: 0.25rem;
        padding: 13px;
        padding-bottom: 0.625rem;
        position: sticky;
        bottom: 5px;
        right: 10px;
        left: 10px;
        color: $standard-font-color;
    }

    .offline-text {
        color: $danger;
        font-weight: 500;
        font-size: 18px;
    }

    .online-text {
        color: $success;
        font-weight: 500;
        font-size: 18px;
    }

    .not-published-text {
        color: $secondary;
        font-weight: 500;
        font-size: 18px;
    }

    .bg-online {
        background-color: $success !important;
        font-weight: 300;
        font-size: 18px;
    }

    .bg-offline {
        background-color: $danger !important;
        font-weight: 300;
        font-size: 18px;
    }

    .bg-neutral {
        background-color: $secondary !important;
        font-weight: 300;
        font-size: 18px;
    }

    .card-information {
        border-collapse: collapse;
    }

    .card-information td {
        padding: 2px;
    }

    .card-description {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        float: left;
        max-width: 100%;
    }

    .card-information td:first-of-type {
        font-weight: 400;
        font-size: 16px;
        padding-left: 0;
    }

    .card-information td:last-of-type {
        font-weight: 300;
        font-size: 16px;
        line-height: 1.4em;
    }
}
