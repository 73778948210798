@import 'node_modules/bootstrap/scss/functions';

// ------------- Bootstrap variables -------------
// enabling or disabling optional features
$enable-shadows: true;
$enable-negative-margins: true;

// color-variables
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #3f7cdd;
$green: #067223;
$yellow: #eea43e;
$red: #bf0a30;
$cyan: #17a2b8;

// theme-color-variables
$primary: $blue;
$secondary: $gray-800;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

// border-radius-variables
$border-radius: 0.3rem;
$border-radius-lg: 0.2rem;
$border-radius-sm: 0.1rem;

// font-variables
$font-family-sans-serif: 'Segoe UI', 'Helvetica Neue', Helvetica, 'Droid Sans', Tahoma, Geneva, sans-serif;
$font-family-monospace: $font-family-sans-serif;
$font-size-base: 0.8rem;
$font-weight-normal: 300;

// grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
);

// btn-variables
$btn-box-shadow:
    0 0.125rem 0.313rem 0 rgba($gray-900, 0.25),
    0 0.125rem 0.625rem 0 rgba($gray-900, 0.25);

$btn-focus-box-shadow:
    0 0.125rem 0.313rem 0 rgba($gray-900, 0.45),
    0 0.125rem 0.625rem 0 rgba($gray-900, 0.45);

$btn-active-box-shadow: $btn-box-shadow;

// ------------- End of  bootstrap variables -------------

// ------------- General variables -----------------------

$standard-fonts: $font-family-sans-serif;
$standard-fonts-size: $font-size-base;
$standard-font-color: $gray-700;
$standard-font-hover-color: $gray-600;
$standard-border-color: $gray-300;
$standard-shadow-color: #0000001a;
$accent-bg: #{shade-color($gray-100, 2.5%)};
$disabled-color: $gray-500;
$default-icon-color: $gray-600;
$light-icon-color: $gray-400;
$standard-link-color: $secondary;
$standard-link-hover-color: #e2e2e2;

$standard-caption-color: #6d6c6c;
$standard-components-background-color: #2f3d560a;
$standard-components-primary-border-color: #3f51b5;
$standard-components-treeview-chips-bg-color: #3f51b5;
$standard-components-secondary-border-color: #a8b5ff;
$standard-components-border-color: #b6b5b5;

$toggle-switch-not-active-state-color: #ccc;
$toggle-switch-background-color: #f7f7f8;

$standard-tabsheet-link-hover-color: #fbcdcf;
$standard-tabsheet-link-active-color: #f3565d;

$default-header-bg-color: #1c1c1c;
$default-border-color: #444444;
$default-header-text-hover-color: #cfcece;

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

$height-breakpoints: (
    xs: 400px,
    sm: 700px,
);

$modal-md: 550px;
