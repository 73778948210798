@import '/src/stylesheets/variables.scss';

body {
    font: $standard-fonts-size $standard-fonts;
    background-color: $gray-100;
    min-height: 100vh;

    // needs to be overspecified to override default css of third party components
    #toast-container {
        .toast-info {
            background-image: url('/assets/images/toast/info.png') !important;
        }

        .toast-error {
            background-image: url('/assets/images/toast/error.png') !important;
        }

        .toast-success {
            background-image: url('/assets/images/toast/success.png') !important;
        }

        .toast-warning {
            background-image: url('/assets/images/toast/warning.png') !important;
        }
    }
}

a {
    cursor: pointer;
    text-decoration: none;
}

hr {
    border: none;
    border: solid 0.6px $gray-500;
    margin: 0 0 15px 0;
}

fieldset {
    border: solid 1px $standard-border-color !important;
    border-radius: 0.313rem;
    padding: 0.5rem;

    legend {
        float: initial;
        padding: initial;
        margin-bottom: initial;
        line-height: initial;
        width: fit-content !important;
        height: fit-content !important;
        margin-left: 0.625rem !important;
        padding-left: 0.313rem !important;
        padding-right: 0.313rem !important;
        font-weight: 350 !important;
        font-size: 0.875rem !important;
    }
}

.content {
    padding: 10px 10px 10px 10px;
    position: relative;
    height: 100%;
}

.row {
    margin-left: -10px;
    margin-right: -10px;
}

.form-text {
    font-size: 12.5px;
    font-weight: 400;
}

.flex-vertical-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.remove-flex-vertical-container {
    display: initial !important;
    flex-direction: initial !important;
    flex-grow: initial !important;
}

.standard-toolbox-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

// Custom btn-group with Radio & Checkbox
.btn-group.btn-sm {
    padding: 0.25rem 0.5rem;

    input[type='radio'],
    input[type='checkbox'] {
        display: none;
    }
}

//----------------

.horizontal-centering {
    margin: auto;
    width: 60%;
    padding: 10px;
    padding-top: 150px;
    text-align: center;
    font-size: 15px;
    color: $gray-600;
}

.vertical-centering {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.standard-tooltip .tooltip-inner {
    text-align: left;
    max-width: 500px;
    margin: auto;
    line-height: 22px;
}

.tooltip {
    pointer-events: none;
    font-size: 0.88rem;
}

.w-20px {
    width: 20px;
}

.disabled {
    pointer-events: none;
    cursor: default;
    color: $disabled-color !important;

    * {
        color: $disabled-color !important;
    }
}

.online,
.offline,
.partial-online {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}

.online {
    background: $success;
}

.offline {
    background: $danger;
}

.partial-online {
    background: linear-gradient(to right, $success 50%, $black 50%, $danger 60%, $danger 100%);
}

.ng-wizard-theme-default {
    box-shadow: none !important;
}

[ngbDatepickerDayView] {
    padding: 0 !important;
}

.ngx-overlay.ngx-position-absolute,
.ngx-progress-bar.ngx-position-absolute {
    top: 0 !important;
    left: 0 !important;
}

.ngx-overlay {
    width: 100% !important;
    height: 100% !important;
}

.no-records-message {
    font-size: 37px !important;
    font-weight: 300 !important;
    padding-left: 10px;
    padding-right: 10px;
}

.no-records-message-detail {
    font-size: 17px !important;
    font-weight: 100 !important;
    padding-left: 10px;
    padding-right: 10px;
}

.device-and-asset-status-position {
    position: relative;
    top: 2px;
}

.dragable {
    background: initial !important;
}

.dragover {
    background: $gray-200 !important;
}

.input-group-text {
    background-color: $gray-200 !important;
}

.auto-scroll-container {
    overflow: auto;
    overflow-anchor: none;
}

.auto-scroll-container::-webkit-scrollbar {
    width: 5px !important;
    height: 6px !important;
    border: 1px solid $gray-100;
}

.auto-scroll-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px var(--sidebar_bg_color);
}

.auto-scroll-container::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px var(--sidebar_bg_color);
}
