@import '/src/stylesheets/variables.scss';

.standard-tabsheet {
    .nav-tabs {
        margin: 0px 14px 0 14px;
    }

    .nav-link {
        border: none;
        border-bottom: solid 4px transparent;
        color: $standard-font-color;
        font-size: 14px;
        font-weight: 350;
        height: 47px;
        line-height: 2.1em;
        margin: 0 1px 0 1px;

        &:hover {
            border: none;
            border-bottom: solid 4px $standard-tabsheet-link-hover-color;
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }
    }

    .nav-link.active {
        border: none;
        border-bottom: solid 4px $standard-tabsheet-link-active-color;
    }
}
