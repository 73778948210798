.shepherd-has-title .shepherd-content .shepherd-header {
    background: $primary !important;
    border-radius: 0.125rem;
    height: 50px;
    box-shadow: 0 2px 5px 0 $standard-shadow-color, 0 2px 10px 0 $standard-shadow-color;
}

.shepherd-title {
    color: $white;
}

.shepherd-footer > * {
    margin: 0.2rem;
}

.shepherd-button.shepherd-button-secondary {
    color: $white !important;
    background-color: $secondary !important;
    border-color: $secondary !important;
}

.shepherd-arrow {
    display: none;
}

.shepherd-element {
    margin: 0.4rem !important;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    opacity: 0.2 !important;
}
