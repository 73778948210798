.monaco-editor .squiggly-error {
    background: url('/assets/images/monaco/red-squiggly.svg') repeat-x bottom left !important;
}

.monaco-custom-checkbox.monaco-simple-checkbox.checked {
    background: url('/assets/images/monaco/checkbox.svg') 50% no-repeat !important;
}

.monaco-custom-checkbox.monaco-simple-checkbox.checked {
    background: url('/assets/images/monaco/simple-checkbox.svg') 50% no-repeat !important;
    background: url('/assets/images/monaco/simple-checkbox-light.svg') 50% no-repeat !important;
}

.vs .monaco-custom-checkbox.monaco-case-sensitive {
    background: url('/assets/images/monaco/case-sensitive.svg') 50% no-repeat !important;
}

.vs-dark .monaco-custom-checkbox.monaco-case-sensitive {
    background: url('/assets/images/monaco/case-sensitive.svg') 50% no-repeat !important;
}

.hc-black .monaco-custom-checkbox.monaco-case-sensitive,
.hc-black .monaco-custom-checkbox.monaco-case-sensitive:hover {
    background: url('/assets/images/monaco/case-sensitive-hover.svg') 50% no-repeat !important;
}

.vs .monaco-custom-checkbox.monaco-preserve-case {
    background: url('/assets/images/monaco/preserve-case.svg') 50% no-repeat !important;
}

.vs-dark .monaco-custom-checkbox.monaco-preserve-case {
    background: url('/assets/images/monaco/vs-dark-preserve-case.svg') 50% no-repeat !important;
}

.hc-black .monaco-custom-checkbox.monaco-preserve-case,
.hc-black .monaco-custom-checkbox.monaco-preserve-case:hover {
    background: url('/assets/images/monaco/hc-black-preserve-case.svg') 50% no-repeat !important;
}

.vs .monaco-custom-checkbox.monaco-whole-word {
    background: url('/assets/images/monaco/whole-word.svg') 50% no-repeat !important;
}

.vs-dark .monaco-custom-checkbox.monaco-whole-word {
    background: url('/assets/images/monaco/vs-dark-whole-word.svg') 50% no-repeat !important;
}

.hc-black .monaco-custom-checkbox.monaco-whole-word,
.hc-black .monaco-custom-checkbox.monaco-whole-word:hover {
    background: url('/assets/images/monaco/hc-blac-whole-word.svg') 50% no-repeat !important;
}

.vs .monaco-custom-checkbox.monaco-regex {
    background: url('/assets/images/monaco/regex.svg') 50% no-repeat !important;
}

.vs-dark .monaco-custom-checkbox.monaco-regex {
    background: url('/assets/images/monaco/vs-dark-regex.svg') 50% no-repeat !important;
}

.hc-black .monaco-custom-checkbox.monaco-regex,
.hc-black .monaco-custom-checkbox.monaco-regex:hover {
    background: url('/assets/images/monaco/hc-black-regex.svg') 50% no-repeat !important;
}

.monaco-list-type-filter > .controls > .filter {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: url('/assets/images/monaco/filter.svg') !important;
    background-position: 50% 50%;
    cursor: pointer;
}

.monaco-list-type-filter > .controls > .filter:checked {
    background-image: url('/assets/images/monaco/filter-checked.svg') !important;
}

.vs-dark .monaco-list-type-filter > .controls > .filter {
    background-image: url('/assets/images/monaco/vs-dark-filter.svg') !important;
}

.vs-dark .monaco-list-type-filter > .controls > .filter:checked {
    background-image: url('/assets/images/monaco/vs-dark-filter-checked.svg') !important;
}

.hc-black .monaco-list-type-filter > .controls > .filter {
    background-image: url('/assets/images/monaco/hc-black-filter.svg') !important;
}

.hc-black .monaco-list-type-filter > .controls > .filter:checked {
    background-image: url('/assets/images/monaco/hc-black-filter-checked.svg') !important;
}

.monaco-list-type-filter > .controls > .clear {
    border: none;
    background: url('/assets/images/monaco/clear.svg') !important;
    cursor: pointer;
}

.hc-black .monaco-list-type-filter > .controls > .clear,
.vs-dark .monaco-list-type-filter > .controls > .clear {
    background-image: url('/assets/images/monaco/hc-black-clear.svg') !important;
}

.monaco-menu .monaco-action-bar.vertical .submenu-indicator {
    height: 100%;
    -webkit-mask: url('/assets/images/monaco/submenu-indicator.svg') no-repeat 90% 50%/13px 13px !important;
    mask: url('/assets/images/monaco/submenu-indicator.svg') no-repeat 90% 50%/13px 13px !important;
}

.monaco-menu .monaco-action-bar.vertical .menu-item-check {
    position: absolute;
    visibility: hidden;
    -webkit-mask: url('/assets/images/monaco/menu-item-check.svg') no-repeat 50% 56%/15px 15px !important;
    mask: url('/assets/images/monaco/menu-item-check.svg') no-repeat 50% 56%/15px 15px !important;
    width: 1em;
    height: 100%;
}

.menubar .toolbar-toggle-more {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 14px;
    width: 20px;
    height: 100%;
    display: inline-block;
    padding: 0;
    -webkit-mask: url('/assets/images/monaco/toolbar-toggle-more.svg') no-repeat 50% 55%/14px 14px !important;
    mask: url('/assets/images/monaco/toolbar-toggle-more.svg') no-repeat 50% 55%/14px 14px !important;
}

.monaco-scrollable-element > .scrollbar > .up-arrow {
    background: url('/assets/images/monaco/up-arrow.svg') !important;
    cursor: pointer;
}

.monaco-scrollable-element > .scrollbar > .down-arrow {
    background: url('/assets/images/monaco/down-arrow.svg') !important;
    cursor: pointer;
}

.monaco-scrollable-element > .scrollbar > .left-arrow {
    background: url('/assets/images/monaco/left-arrow.svg') !important;
    cursor: pointer;
}

.monaco-scrollable-element > .scrollbar > .right-arrow {
    background: url('/assets/images/monaco/right-arrow.svg') !important;
    cursor: pointer;
}

.hc-black .monaco-scrollable-element > .scrollbar > .up-arrow,
.vs-dark .monaco-scrollable-element > .scrollbar > .up-arrow {
    background: url('/assets/images/monaco/hc-black-up-arrow.svg') !important;
}

.hc-black .monaco-scrollable-element > .scrollbar > .down-arrow,
.vs-dark .monaco-scrollable-element > .scrollbar > .down-arrow {
    background: url('/assets/images/monaco/hc-black-down-arrow.svg') !important;
}

.hc-black .monaco-scrollable-element > .scrollbar > .left-arrow,
.vs-dark .monaco-scrollable-element > .scrollbar > .left-arrow {
    background: url('/assets/images/monaco/hc-black-left-arrow.svg') !important;
}

.hc-black .monaco-scrollable-element > .scrollbar > .right-arrow,
.vs-dark .monaco-scrollable-element > .scrollbar > .right-arrow {
    background: url('/assets/images/monaco/hc-black-right-arrow.svg') !important;
}

.monaco-tl-twistie.collapsible {
    background-size: 16px;
    background-position: 3px 50%;
    background-repeat: no-repeat;
    background-image: url('/assets/images/monaco/collapsible.svg') !important;
}

.monaco-tl-twistie.collapsible.collapsed:not(.loading) {
    display: inline-block;
    background-image: url('/assets/images/monaco/collapsed-not-loaded.svg') !important;
}

.vs-dark .monaco-tl-twistie.collapsible:not(.loading) {
    background-image: url('/assets/images/monaco/vs-dark-collapsible.svg') !important;
}

.vs-dark .monaco-tl-twistie.collapsible.collapsed:not(.loading) {
    background-image: url('/assets/images/monaco/vs-dark-collapsible-collapsed.svg') !important;
}

.hc-black .monaco-tl-twistie.collapsible:not(.loading) {
    background-image: url('/assets/images/monaco/hc-black-collapsible.svg') !important;
}

.hc-black .monaco-tl-twistie.collapsible.collapsed:not(.loading) {
    background-image: url('/assets/images/monaco/hc-black-collapsible.svg') !important;
}

.monaco-tl-twistie.loading {
    background-image: url('/assets/images/monaco/loading.svg') !important;
    background-position: 0;
}

.vs-dark .monaco-tl-twistie.loading {
    background-image: url('/assets/images/monaco/vs-dark-loading.svg') !important;
}

.hc-black .monaco-tl-twistie.loading {
    background-image: url('/assets/images/monaco/hc-black-loading.svg') !important;
}

.monaco-tree .monaco-tree-rows.show-twisties > .monaco-tree-row.has-children > .content:before {
    content: ' ';
    position: absolute;
    display: block;
    background: url('/assets/images/monaco/tree-has-children.svg') 50% 50% no-repeat !important;
    width: 16px;
    height: 100%;
    top: 0;
    left: -16px;
}

.monaco-tree .monaco-tree-rows.show-twisties > .monaco-tree-row.expanded > .content:before {
    background-image: url('/assets/images/monaco/tree-row-expanded.svg') !important;
}

.monaco-tree .monaco-tree-rows > .monaco-tree-row.has-children.loading > .content:before {
    background-image: url('/assets/images/monaco/loading.svg') !important;
}

.vs-dark .monaco-tree .monaco-tree-rows.show-twisties > .monaco-tree-row.has-children > .content:before {
    background-image: url('/assets/images/monaco/vs-dark-collapsible-collapsed.svg') !important;
}

.vs-dark .monaco-tree .monaco-tree-rows.show-twisties > .monaco-tree-row.expanded > .content:before {
    background-image: url('/assets/images/monaco/vs-dark-tree-row-expanded.svg') !important;
}

.vs-dark .monaco-tree .monaco-tree-rows > .monaco-tree-row.has-children.loading > .content:before {
    background-image: url('/assets/images/monaco/vs-dark-loading.svg') !important;
}

.hc-black .monaco-tree .monaco-tree-rows.show-twisties > .monaco-tree-row.has-children > .content:before {
    background-image: url('/assets/images/monaco/hc-black-collapsible.svg') !important;
}

.hc-black .monaco-tree .monaco-tree-rows.show-twisties > .monaco-tree-row.expanded > .content:before {
    background-image: url('/assets/images/monaco/hc-black-tree-row-expanded.svg') !important;
}

.hc-black .monaco-tree .monaco-tree-rows > .monaco-tree-row.has-children.loading > .content:before {
    background-image: url('/assets/images/monaco/hc-black-loading.svg') !important;
}

.monaco-tree-action.collapse-all {
    background: url('/assets/images/monaco/collapse-all.svg') 50% no-repeat !important;
}

.vs-dark .monaco-tree-action.collapse-all {
    background: url('/assets/images/monaco/vs-dark-collapse-all.svg') 50% no-repeat !important;
}

.hc-black .monaco-tree-action.collapse-all {
    background: url('/assets/images/monaco/hc-black-collapse-all.svg') 50% no-repeat !important;
}

.monaco-editor .margin-view-overlays .line-numbers {
    cursor: -webkit-image-set(url('/assets/images/monaco/line-numbers.svg') 1x, url('/assets/images/monaco/line-numbers-2x.svg') 2x) 30 0, default !important;
}

.monaco-editor.mac .margin-view-overlays .line-numbers {
    cursor: -webkit-image-set(url('/assets/images/monaco/mac-line-numbers.svg') 1x, url('/assets/images/monaco/mac-line-numbers-2x.svg') 2x) 24 3, default !important;
}

.monaco-editor.hc-black.mac .view-lines,
.monaco-editor.vs-dark.mac .view-lines {
    cursor: -webkit-image-set(url('/assets/images/monaco/view-lines.png') 1x, url('/assets/images/monaco/view-lines-2x.png') 2x) 5 8, text !important;
}

.monaco-diff-editor .insert-sign,
.monaco-editor .insert-sign {
    background-image: url('/assets/images/monaco/insert-sign.svg') !important;
}

.monaco-diff-editor .delete-sign,
.monaco-editor .delete-sign {
    background-image: url('/assets/images/monaco/delete-sign.svg') !important;
}

.monaco-diff-editor.hc-black .insert-sign,
.monaco-diff-editor.vs-dark .insert-sign,
.monaco-editor.hc-black .insert-sign,
.monaco-editor.vs-dark .insert-sign {
    background-image: url('/assets/images/monaco/hc-black-insert-sign.svg') !important;
}

.monaco-diff-editor.hc-black .delete-sign,
.monaco-diff-editor.vs-dark .delete-sign,
.monaco-editor.hc-black .delete-sign,
.monaco-editor.vs-dark .delete-sign {
    background-image: url('/assets/images/monaco/hc-black-delete-sign.svg') !important;
}

.monaco-editor .diagonal-fill {
    background: url('/assets/images/monaco/diagonal-fill.png') !important;
}

.monaco-editor .margin-view-zones .inline-deleted-margin-view-zone .lightbulb-glyph {
    background: url('/assets/images/monaco/lightbulb-glyph.svg') 50% no-repeat !important;
}

.monaco-editor.hc-dark .margin-view-zones .inline-deleted-margin-view-zone .lightbulb-glyph,
.monaco-editor.vs-dark .margin-view-zones .inline-deleted-margin-view-zone .lightbulb-glyph {
    background: url('/assets/images/monaco/hc-dark-lightbulb-glyph.svg') 50% no-repeat !important;
}

.monaco-diff-editor .action-label.icon.close-diff-review {
    background: url('/assets/images/monaco/close-diff-review.svg') 50% no-repeat !important;
}

.monaco-diff-editor.hc-black .action-label.icon.close-diff-review,
.monaco-diff-editor.vs-dark .action-label.icon.close-diff-review {
    background: url('/assets/images/monaco/hc-black-close-diff-review.svg') 50% no-repeat !important;
}

.monaco-editor.vs .lightbulb-glyph {
    background: url('/assets/images/monaco/vs-lightbulb-glyph.svg') 50% no-repeat !important;
}

.monaco-editor.vs .lightbulb-glyph.autofixable {
    background: url('/assets/images/monaco/lightbulb-glyph-autofixable.svg') 50% no-repeat !important;
}

.monaco-editor.hc-black .lightbulb-glyph,
.monaco-editor.vs-dark .lightbulb-glyph {
    background: url('/assets/images/monaco/hc-black-lightbulb-glyph.svg') 50% no-repeat !important;
}

.monaco-editor.hc-black .lightbulb-glyph.autofixable,
.monaco-editor.vs-dark .lightbulb-glyph.autofixable {
    background: url('/assets/images/monaco/hc-black-lightbulb-glyph-autofixable.svg') 50% no-repeat !important;
}

.monaco-editor .find-widget .previous {
    background-image: url('/assets/images/monaco/find-widget-previous.svg') !important;
}

.monaco-editor .find-widget .next {
    background-image: url('/assets/images/monaco/find-widget-next.svg') !important;
}

.monaco-editor .find-widget .monaco-checkbox .label {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    background-image: url('/assets/images/monaco/filter.svg') !important;
    width: 20px;
    height: 20px;
    border: none;
}

.monaco-editor .find-widget .close-fw {
    background-image: url('/assets/images/monaco/close-fw.svg') !important;
}

.monaco-editor .find-widget .expand {
    background-image: url('/assets/images/monaco/find-widget-expand.svg') !important;
}

.monaco-editor .find-widget .collapse {
    background-image: url('/assets/images/monaco/find-widget-collapse.svg') !important;
}

.monaco-editor .find-widget .replace {
    background-image: url('/assets/images/monaco/find-widget-replace.svg') !important;
}

.monaco-editor .find-widget .replace-all {
    background-image: url('/assets/images/monaco/find-widget-replace-all.svg') !important;
}

.monaco-editor .margin-view-overlays .folding {
    cursor: pointer;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-position: calc(50% + 2px) 50%;
    background-size: auto calc(100% - 3px);
    opacity: 0;
    transition: opacity 0.5s;
    background-image: url('/assets/images/monaco/collapsible.svg') !important;
}

.monaco-editor.hc-black .margin-view-overlays .folding,
.monaco-editor.vs-dark .margin-view-overlays .folding {
    background-image: url('/assets/images/monaco/vs-dark-collapsible.svg') !important;
}

.monaco-editor.hc-black .margin-view-overlays .folding {
    background-image: url('/assets/images/monaco/hc-black-collapsible.svg') !important;
}

.monaco-editor .margin-view-overlays .folding.collapsed {
    background-image: url('/assets/images/monaco/folding-collapsed.svg') !important;
    opacity: 1;
}

.monaco-editor .parameter-hints-widget .button.previous {
    bottom: 24px;
    background-image: url('/assets/images/monaco/parameter-hints-widget-previous.svg') !important;
}

.monaco-editor .parameter-hints-widget .button.next {
    bottom: 0;
    background-image: url('/assets/images/monaco/parameter-hints-widget-next.svg') !important;
}

.monaco-editor .peekview-widget .head .peekview-actions .action-label.icon.close-peekview-action {
    background: url('/assets/images/monaco/close-peekview-action.svg') 50% no-repeat !important;
}

.monaco-editor .peekview-widget .peekview-actions .icon.chevron-up {
    background: url('/assets/images/monaco/icon.chevron-up.svg') 50% no-repeat !important;
}

.monaco-editor .peekview-widget .peekview-actions .icon.chevron-down {
    background: url('/assets/images/monaco/icon.chevron-down.svg') 50% no-repeat !important;
}

.monaco-editor .suggest-widget .details > .monaco-scrollable-element > .body > .header > .close {
    background-image: url('/assets/images/monaco/monaco-scrollable-element-close.svg') !important;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 5px;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > .main > .readMore {
    background-image: url('/assets/images/monaco/readMore.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.constructor:before,
.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.function:before,
.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.method:before {
    background-image: url('/assets/images/monaco/suggest-icon-constructor.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.field:before {
    background-image: url('/assets/images/monaco/suggest-icon-field.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.event:before {
    background-image: url('/assets/images/monaco/suggest-icon-event.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.operator:before {
    background-image: url('/assets/images/monaco/suggest-icon-operator.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.variable:before {
    background-image: url('/assets/images/monaco/suggest-icon-variable.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.class:before {
    background-image: url('/assets/images/monaco/suggest-icon-class.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.interface:before {
    background-image: url('/assets/images/monaco/suggest-icon-interface.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.struct:before {
    background-image: url('/assets/images/monaco/suggest-icon-struct.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.type-parameter:before {
    background-image: url('/assets/images/monaco/suggest-icon-type-parameter.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.module:before {
    background-image: url('/assets/images/monaco/suggest-icon-module.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.property:before {
    background-image: url('/assets/images/monaco/suggest-icon-property.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.unit:before {
    background-image: url('/assets/images/monaco/suggest-icon-unit.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.constant:before {
    background-image: url('/assets/images/monaco/suggest-icon-constant.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.enum:before,
.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.value:before {
    background-image: url('/assets/images/monaco/suggest-icon-enum.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.enum-member:before {
    background-image: url('/assets/images/monaco/suggest-icon-enum-member.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.keyword:before {
    background-image: url('/assets/images/monaco/suggest-icon-keyword.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.text:before {
    background-image: url('/assets/images/monaco/suggest-icon-text.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.color:before {
    background-image: url('/assets/images/monaco/suggest-icon-color.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.file:before {
    background-image: url('/assets/images/monaco/suggest-icon-file.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.reference:before {
    background-image: url('/assets/images/monaco/suggest-icon-reference.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.snippet:before {
    background-image: url('/assets/images/monaco/suggest-icon-snippet.svg') !important;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row .suggest-icon.folder:before {
    background-image: url('/assets/images/monaco/suggest-icon-folder.svg') !important;
}

.monaco-editor .iPadShowKeyboard {
    width: 58px;
    min-width: 0;
    height: 36px;
    min-height: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    resize: none;
    overflow: hidden;
    background: url('/assets/images/monaco/iPadShowKeyboard.svg') 50% no-repeat !important;
    border: 4px solid #f6f6f6;
    border-radius: 4px;
}

.monaco-editor .monaco-editor-hover-content .code:nth-child(1) {
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px $standard-border-color;
    padding-bottom: 10px;
}
