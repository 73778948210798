.popover-body {
    ngb-timepicker {
        .btn-link {
            font-size: 10px !important;
        }

        fieldset {
            border: none !important;
            border-radius: 0;
            padding: 0;
        }

        .ngb-tp-input {
            height: 25px;
            border-radius: 0;
        }

    }
    .ngb-dp-content {
        font-size: 14px !important;
        font-weight: 400 !important;
    }
}

.ngb-dp-month-name {
    font-size: 16px !important;
    font-weight: 300;
}

.ngb-dp-navigation-chevron {
    font-size: 10px;
}

.ngb-dp-arrow {
    .ngb-dp-arrow-btn {
        margin: 0 0.5rem !important;
    }
}

.ngb-tp-input-container {
    .btn {
        margin: 0px !important;
    }
    .form-control {
        font-size: unset;
    }
}

.ngb-dp-arrow,
.ngb-tp-input-container {
    .btn {
        font-size: var(--bs-btn-font-size) !important;
    }

    .btn, .btn:hover {
        box-shadow: none !important;
    }
}


