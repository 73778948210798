@import '/src/stylesheets/variables.scss';

.border-radius {
    border-radius: 0.25rem !important;
}

.border-all {
    border: solid 1px $standard-border-color !important;
}

.border-top {
    border-top: solid 1px $standard-border-color !important;
}

.border-bottom {
    border-bottom: solid 1px $standard-border-color !important;
}

.border-start {
    border-left: solid 1px $standard-border-color !important;
}

.border-end {
    border-right: solid 1px $standard-border-color !important;
}

.component-container {
    @extend .card;
    border: 0;
    border-radius: 0;
    box-shadow: 5px 5px 12px $standard-shadow-color;
    height: 100%;

    .card-header {
        font-size: 18px;
        font-weight: 300;
        padding-left: 0;
        padding-right: 0;
        padding-top: 1.5384rem;
        margin: 0 1.538rem;
        border: none;
        border-radius: 3px 3px 0 0;
        background-color: transparent;
        min-height: 76px;
    }

    .card-header-bordered {
        font-size: 18px;
        font-weight: 300;
        padding-left: 1.538rem;
        padding-right: 1.538rem;
        padding-top: 18px;
        border-bottom-width: 0;
        border-radius: 3px 3px 0 0;
        background-color: transparent;
        border-bottom: 1px solid $standard-border-color;
        margin: 0 1.538rem 8px;
        margin-left: 0;
        margin-right: 0;
        line-height: 2.8em;
    }

    .card-body {
        padding: 0;
        flex: 1 1 auto;
        min-height: 150px;

        .table-container {
            margin: 20px 15px 20px 15px;
        }

        .table-header {
            @extend .row;
            font-weight: 400;
            font-size: 15px;
            border-bottom: solid 2px $standard-border-color;
            padding: 11px 12px 11px 12px;
        }

        .table-row {
            @extend .row;
            padding: 9px 12px 9px 12px;
        }

        .col {
            padding: 0;
        }
    }

    .card-footer {
        border-top: solid 1px $standard-border-color;
        padding: 5px 12px 5px 12px;
        height: 50px;
        color: $standard-font-color;
        font-style: italic;
        line-height: 3em;
    }

    .table {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .table thead,
    .table thead th {
        font-weight: 400;
        font-size: 15px;
    }

    .table tr {
        border-top: hidden;
    }

    .table tr td {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .table tbody tr:first-of-type {
        border-top: solid 1px $standard-border-color;
    }

    .pagination {
        margin-top: 2px;
        font-style: normal;
    }

    .clickable:hover {
        cursor: pointer;
        background-color: $standard-border-color;
    }
}
//-----------------------------------
